import React from "react"
// import { Link } from "gatsby"
import { Helmet } from "react-helmet"

import SiniestrosLayout from "../../components/siniestros-layout"

import icoMercantil from "../../assets/old/img/logos-ase/ico-mercantilandina.png";

export default () => (
    <SiniestrosLayout subCompaniaActivo="mercantil">
        <Helmet>
            <title>Mercantil Andina Seguros - Siniestros</title>
            <meta
                name="description"
                content="Servicio de asistencia Mercantil Andina Seguros:  0800-888-4488."
            />
        </Helmet>

        <hr className="mb-4" />
        {/* Quotation */}
        <div className="card blog-card mb-2">
            <div className="card-body px-sm-5 py-5 bg-secondary">
                <img
                    className="float-md-right pb-2"
                    src={icoMercantil}
                    alt="Mercantil Andina Seguros"
                />
                <h4 className="lead post-title text-muted">Mercantil Andina Seguros</h4>
                <p className="">Servicio de asistencia:  0800-888-4488.</p>
            </div>
        </div>
        <p className="pt-5 pb-3">
            Recordá que ante un siniestro la denuncia en Mercantil andina deberá realizarla dentro de las 72 horas hábiles de ocurrido el hecho.
        </p>
        <h6>En caso de un siniestro</h6>
        <p>
            La denuncia del siniestro se deberá realizar “siempre” que el vehículo asegurado haya participado de un accidente.
        </p>
        <h6>¿Cómo actuar en caso de un siniestro de automotores?</h6>
        <p>
            Realizar la Denuncia Administrativa: Deberá presentarse en cualquiera de nuestras oficinas con los siguientes datos que figuran a continuación.
        </p>



        <hr className="pb-4" />



    </SiniestrosLayout>
)
